import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';

import { Button, ErrorMessage, Heading } from '../../shared';
import { runEventHandler } from '../../utils';
import {
  DisabledAccountError,
  InvalidOrExpiredCodeError,
  Login
} from '../../api/login';
import { SidebarLayout } from '../../shared/SidebarLayout/SidebarLayout';
import { colors, widths } from '../../constants';
import ResendButton from './ResendButton';
import { UserFriendlyError } from '../../utils/runEventHandler';
import generateSignUpLink from '../../utils/generateSignUpLink';

interface Props {
  login: Login;
}

type CodeType =
  | 'oauth-code-0'
  | 'oauth-code-1'
  | 'oauth-code-2'
  | 'oauth-code-3'
  | 'oauth-code-4'
  | 'oauth-code-5'
  | 'oauth-code-6'
  | 'oauth-code-7';

type FormData = Record<CodeType, string>;

const CODE_LENGTH = 8;

const IGNORED_KEYS = ['Backspace', 'Meta'];

export function Code(props: Props) {
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    setFocus,
    reset
  } = useForm<FormData>();

  const generateCodeName = (index: number) => `oauth-code-${index}` as CodeType;

  useEffect(() => {
    setFocus(generateCodeName(0));
  }, [setFocus]);

  const isLoading = isSubmitting || isSubmitSuccessful;

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    if (key === 'Backspace') {
      if (target.value.trim() === '') {
        if (target.previousElementSibling !== null) {
          const t = target.previousElementSibling as HTMLInputElement;
          t.value = '';
          t.focus();
          e.preventDefault();
        }
      } else {
        target.value = '';
      }
    }
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (!IGNORED_KEYS.includes(key)) {
      if (target.value.trim() === '') {
        target.value = '';
      } else {
        if (target.nextElementSibling !== null) {
          (target.nextElementSibling as HTMLInputElement).focus();
        }
      }
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('Text').trim();

    const initialName = (e.target as HTMLInputElement).name;
    const initialIndex = parseInt(initialName.split('-').pop() || '0');

    for (let i = initialIndex, j = 0; i < CODE_LENGTH; i++, j++) {
      setValue(generateCodeName(i), pastedValue.charAt(j));
    }

    let lastIndex = initialIndex + pastedValue.length;

    if (lastIndex > CODE_LENGTH - 1) {
      lastIndex = CODE_LENGTH - 1;
    }

    setFocus(generateCodeName(lastIndex));

    e.preventDefault();
  };

  const onSubmit = async (data: FormData) => {
    const currentCode = Object.values(data).join('');

    const redirectUri = state.redirect_uri;
    try {
      const { code, client } = await props.login.getAuthorizationCode(
        state.email,
        state.password,
        currentCode,
        state.client_id,
        redirectUri
      );

      const query = new URLSearchParams({
        code,
        state: state?.state || '',
        client
      }).toString();

      sessionStorage.clear();
      window.location.assign(`${redirectUri}?${query}`);
    } catch (err) {
      reset();
      if (err instanceof InvalidOrExpiredCodeError) {
        throw new UserFriendlyError(
          'The code you have entered is incorrect. Please request a new code.'
        );
      } else if (err instanceof DisabledAccountError) {
        throw new UserFriendlyError('Your account has been disabled');
      }
      throw err;
    }
  };

  const missingValues = Object.values(errors).length > 0;

  if (!state?.email || !state.password) {
    return <Navigate to=".." replace />;
  }

  return (
    <SidebarLayout
      sideBarPosition="left"
      tagline="Don't have an account?"
      link={{
        path: generateSignUpLink(state),
        name: 'Sign up'
      }}
    >
      <Form
        id="code-form"
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading
          title="Authentication code"
          subtitle={`An authentication code has been sent to your email. 
          Please copy and paste it below.`}
        />

        <CodeContainer>
          {Array(CODE_LENGTH)
            .fill('')
            .map((_, index) => (
              <CodeInput
                key={index}
                type="text"
                maxLength={1}
                aria-label={`auth code ${index}`}
                autoComplete="one-time-code"
                onKeyDown={handleOnKeyDown}
                onKeyUp={handleOnKeyUp}
                onFocus={handleOnFocus}
                onPaste={handleOnPaste}
                {...register(generateCodeName(index), {
                  required: true
                })}
              />
            ))}
        </CodeContainer>

        <ErrorMessage className="error">
          {missingValues ? 'Please enter your authentication code' : ''}
        </ErrorMessage>
      </Form>

      <Footer>
        <ResendButton
          email={state?.email}
          password={state?.password}
          login={props.login}
          onResend={reset}
        />
        <Button
          type="submit"
          form="code-form"
          isLoading={isLoading}
          disabled={missingValues}
        >
          Submit
        </Button>
      </Footer>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;
  padding-top: 25px;
  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    padding-top: 0px;
  }
`;

const CodeContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 27px;
  margin-bottom: 20px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    gap: 6px;
    margin: 0px;
  }
`;

const CodeInput = styled.input`
  border-radius: 8px;
  border: 1px solid ${colors.DARK_BLUE};
  height: 52px;
  width: 40px;
  font-size: 28px;
  text-align: center;
  font-weight: bold;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    height: 42px;
    width: 30px;
    font-size: 18px;
  }

  :focus-visible {
    border: 2px solid ${colors.DARK_BLUE};
    outline: none;
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  flex: 1;

  button {
    margin-right: 24px;
  }
`;
